<template>
	<div>
		<base-table :items="items" :headers="headers" table-top paginate>
			<template #action>
				<loading-btn :loading="loading" variant="primary" @click="exportExcel">Export Excel</loading-btn>
			</template>
			<template #cell()="{ value }">
				<feather-icon v-if="value" icon="CheckIcon" size="18" style="color: green" />
				<feather-icon v-else icon="XIcon" size="18" style="color: red" />
			</template>
			<template #cell(id)="{ value }">
				<span>{{ value }}</span>
			</template>
			<template #cell(title)="{ value }">
				<span>{{ value }}</span>
			</template>
		</base-table>
	</div>
</template>

<script>
import BaseTable from '@/components/BaseTable.vue';
import api from '@/utils/api';
import LoadingBtn from '@/components/LoadingBtn.vue';

export default {
	name: 'ProjectForms',

	components: { BaseTable, LoadingBtn },

	data: () => ({
		items: [],

		loading: false,

		headers: [
			{ key: 'id', label: 'ID' },
			{ key: 'title', label: 'Name' },
			{ key: '0', label: 'استمارة المسح القاعدي للمجتمعات المستفيدة من مشروع-جميع القطاعات' },
			{ key: '1', label: 'متطلبات الحماية البيئية والاجتماعية خلال مرحلة الاعداد للمشروع' },
			{ key: '2', label: 'استمارة المتطلبات البيئية اثناء تجهيز التصاميم للمشروع' },
			{ key: '3', label: 'مخرجات تحديد الاحتياجات /النوع الاجتماعي/ الشكاوي' },
			{ key: '4', label: 'مخرجات المشاركة المجتمعية / التنمية الحساسة للنزاع' },
			{ key: '5', label: 'استمارة استبيان حول حماية المرأة والنوع الاجتماعي' },
			{ key: '6', label: 'مخرجات التدريب والاوبائه والامراض' },
			{ key: '7', label: 'استمارة المسح القاعدي للأسر المستفيدة' },
			{ key: '8', label: 'تقييم الوضع الحالي للمقاولين المنفذين للمشاريع قبل البداء في تنفيذ العمل' },
			{ key: '9', label: 'تسليم موقع' },
		],
	}),

	created() {
		this.loadData();
	},

	methods: {
		async loadData() {
			const { data } = await api.call({ path: 'reports/project-forms', method: 'GET' });
			console.log(data);

			this.items = data;
		},

		exportExcel() {
			this.loading = true;

			api.getFile('reports/project-forms/export')
				.then((response) => {
					const url = URL.createObjectURL(
						new Blob([response.data], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						}),
					);

					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'project-forms');
					document.body.appendChild(link);
					link.click();
					this.loading = false;
				})
				.catch((error) => {
					console.log(error);
					this.loading = false;
				});
		},
	},
};
</script>

<style lang="scss" scoped></style>
